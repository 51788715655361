var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading && !_vm.profilePage && _vm.data.properties.filed_content != 'Hide')?_c('div',[_c('el-row',{attrs:{"align":"middle"}},[(!_vm.data.properties.hideLabel)?_c('el-col',{style:(_vm.computedStyles),attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 4 : _vm.data.description ? 20 : 24}},[(_vm.hasLabel && !_vm.data.properties.hideLabel)?_c('label',{style:(_vm.getLabelStyles),attrs:{"for":"text"}},[_vm._v(" "+_vm._s(_vm.label || _vm.data.label)+" ")]):_vm._e(),(_vm.data.validations.required && _vm.hasLabel && !_vm.isView)?_c('span',{staticStyle:{"color":"red","font-weight":"bold","margin-left":"5px","margin-right":"5px"}},[_vm._v("*")]):_vm._e(),_c('span',[(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1)]):_c('el-col',{ref:"colRef",style:(_vm.computedStyles),attrs:{"span":_vm.showLabel ? 4 : _vm.data.description ? 20 : 24}},[(_vm.hasLabel)?_c('span',{staticStyle:{"color":"white","display":"inline-block","height":"20px","opacity":"0"}}):_vm._e(),(_vm.data.validations.required && _vm.hasLabel && !_vm.isView)?_c('span',{staticStyle:{"color":"red","font-weight":"bold","margin-left":"5px","margin-right":"5px"}},[_vm._v("*")]):_vm._e()]),_c('el-col',{attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 12 : 24}},[(
          _vm.isList &&
          !_vm.isView &&
          _vm.data.properties.filed_content != 'Hide' &&
          this.data.properties.time_settings === 'Range'
        )?_c('el-time-picker',{staticClass:"eb-ctm-input-time",style:(_vm.getStyle),attrs:{"prefix-icon":"el-icon-time","placeholder":_vm.data.placeholder || _vm.data.label,"disabled":_vm.isFromDocument ? false : _vm.checkReadonly(),"readonly":_vm.readonly(),"picker-options":_vm.getPickerOptions(),"value-format":'HH:mm:ss'},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e(),(_vm.isList && !_vm.isView && _vm.data.properties.filed_content != 'Hide')?_c('el-time-picker',{staticClass:"eb-ctm-input-time",style:(_vm.getStyle),attrs:{"prefix-icon":"el-icon-time","placeholder":_vm.data.placeholder || _vm.data.label,"disabled":_vm.isFromDocument ? false : _vm.checkReadonly(),"readonly":_vm.readonly(),"picker-options":{
          format: _vm.getGlobalTimeFormat(),
        },"value-format":_vm.getGlobalTimeFormat()},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e(),(
          !_vm.isView &&
          !_vm.isList &&
          _vm.data.properties.filed_content != 'Hide' &&
          _vm.data.is_fixed
        )?_c('el-time-select',{staticClass:"eb-ctm-input-time",class:{ 'highlighted-border': _vm.highlight },style:(_vm.getStyle),attrs:{"picker-options":_vm.getTimeProperties,"readonly":_vm.readonly(),"placeholder":_vm.data.placeholder || _vm.data.label,"disabled":_vm.isFromDocument ? false : _vm.checkReadonly(),"prefix-icon":"el-icon-time"},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):(
          !_vm.isView &&
          !_vm.isList &&
          _vm.data.properties.filed_content != 'Hide' &&
          this.data.properties.time_settings === 'Range'
        )?_c('el-time-picker',{staticClass:"eb-ctm-input-time",style:(_vm.getStyle),attrs:{"prefix-icon":"el-icon-time","placeholder":_vm.data.placeholder || _vm.data.label,"disabled":_vm.isFromDocument ? false : _vm.checkReadonly(),"readonly":_vm.readonly(),"picker-options":_vm.getPickerOptions(),"value-format":'HH:mm:ss'},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):(
          !_vm.isView && !_vm.isList && _vm.data.properties.filed_content != 'Hide'
        )?_c('el-time-picker',{staticClass:"eb-ctm-input-time",class:{ 'highlighted-border': _vm.highlight },style:(_vm.getStyle),attrs:{"prefix-icon":"el-icon-time","placeholder":_vm.data.placeholder || _vm.data.label,"disabled":_vm.isFromDocument ? false : _vm.checkReadonly(),"readonly":_vm.readonly(),"picker-options":{
          format: _vm.getGlobalTimeFormat(),
        },"value-format":'HH:mm:ss'},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e()],1)],1),(_vm.isView && _vm.data.properties.filed_content != 'Hide')?_c('p',{style:(_vm.getStyle)},[(_vm.form[_vm.data.key])?[_vm._v(_vm._s(_vm._f("moment")(_vm.getGlobalFormattedTime(_vm.form[_vm.data.key]),"HH:mm:ss")))]:[_vm._v("-")]],2):_vm._e()],1):_c('div',[(_vm.isView)?_c('div',[_vm._v(_vm._s(_vm.getGlobalFormattedTime(_vm.form[_vm.data.key])))]):_c('div',[(
        !_vm.isView && _vm.data.properties.filed_content != 'Hide' && !_vm.data.is_fixed
      )?_c('el-time-picker',{staticClass:"eb-ctm-input-time",style:(_vm.getStyle),attrs:{"prefix-icon":"el-icon-time","placeholder":_vm.data.placeholder || _vm.data.label,"disabled":_vm.isFromDocument ? false : _vm.checkReadonly(),"readonly":_vm.readonly(),"picker-options":{
        format: 'HH:mm',
      },"value-format":'HH:mm'},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e(),(
        !_vm.isView && _vm.data.properties.filed_content != 'Hide' && _vm.data.is_fixed
      )?_c('el-time-select',{staticClass:"eb-ctm-input-time",style:(_vm.getStyle),attrs:{"picker-options":_vm.getTimeProperties,"readonly":_vm.readonly(),"placeholder":_vm.data.placeholder || _vm.data.label,"disabled":_vm.isFromDocument ? false : _vm.checkReadonly(),"prefix-icon":"el-icon-time"},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }