var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading && _vm.data.properties.filed_content != 'Hide')?_c('div',{style:(_vm.getElementStyle)},[_c('el-row',{attrs:{"align":"middle"}},[(!_vm.data.properties.hideLabel)?_c('el-col',{style:(_vm.computedStyles),attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 4 : _vm.data.description ? 20 : 24}},[(_vm.hasLabel && _vm.data.properties.filed_content != 'Hide')?_c('label',{style:(_vm.getLabelStyles),attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.label || _vm.data.label))]):_vm._e(),(_vm.data.validations.required && _vm.hasLabel && !_vm.isView)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e(),_c('span',[(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1)]):_c('el-col',{ref:"colRef",style:(_vm.computedStyles),attrs:{"span":_vm.showLabel ? 4 : _vm.data.description ? 20 : 24}},[(_vm.hasLabel)?_c('span',{staticClass:"transparentcol"}):_vm._e()]),_c('el-col',{attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 12 : 24}},[[(
            !_vm.isView &&
            !_vm.isList &&
            _vm.data.properties.filed_content != 'Hide' &&
            _vm.initialValue
          )?_c('currency-input',{staticClass:"el-input__inner",class:{
            'left-aligned-input': _vm.inputTextAlignment === 'left',
            'right-aligned-input': _vm.inputTextAlignment === 'right',
            'center-aligned-input': _vm.inputTextAlignment === 'center',
            'highlight-border': _vm.highlight,
          },style:(_vm.getCurrencyStyle),attrs:{"placeholder":_vm.data.placeholder || _vm.data.label,"currency":_vm.initialValue,"locale":_vm.data.validations.locale,"suffix-icon":_vm.data.properties.el_icon,"disabled":_vm.isFromDocument ? false : _vm.checkReadonly(),"readonly":_vm.readonly()},on:{"blur":_vm.onInput,"change":function($event){return _vm.applyFormRules($event)}},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e(),(_vm.isList && !_vm.isView && _vm.initialValue)?_c('currency-input',{staticClass:"el-input__inner",class:{
            'left-aligned-input': _vm.textAlignment === 'left',
            'right-aligned-input': _vm.textAlignment === 'right',
            'center-aligned-input': _vm.textAlignment === 'center',
          },style:(_vm.getStyle),attrs:{"size":_vm.profilePage ? 'mini' : '',"placeholder":_vm.data.placeholder || _vm.data.label,"suffix-icon":_vm.data.properties.el_icon,"currency":_vm.initialValue,"locale":_vm.data.validations.locale,"disabled":_vm.isFromDocument ? false : _vm.checkReadonly(),"readonly":_vm.readonly()},on:{"blur":_vm.onInput,"change":function($event){return _vm.applyFormRules($event)}},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e(),(_vm.isView)?_c('p',{class:{ 'currency-sty': !_vm.data.is_entity_variable },style:(_vm.getStyle)},[_vm._v(" "+_vm._s(_vm.formattedCurrency)+" ")]):_vm._e(),(_vm.hasError)?_c('p',{staticStyle:{"color":"red","font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]):_vm._e()]],2)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }